const SALogo = props => (
	<div className={'robert-logo'}>
		<img src={'/robert-logo.png'}/>

		<style jsx>{`
			.robert-logo {
				height: 70px;
				
				> img {
					height: 100%;
				}
			}
		`}</style>
	</div>
)

export default SALogo;
